// extracted by mini-css-extract-plugin
export var accImg = "OnBoard-module--accImg--404ae";
export var description = "OnBoard-module--description--b4d1e";
export var heading = "OnBoard-module--heading--bbc79";
export var hireCTOonBoard_White_BTN = "OnBoard-module--hireCTOonBoard_White_BTN--9d1aa";
export var nextGenRight = "OnBoard-module--nextGenRight--d7a37";
export var onBoardCTO = "OnBoard-module--onBoardCTO--488ea";
export var onBoardHireCTOBTN = "OnBoard-module--onBoardHireCTOBTN--fbecc";
export var quesColor = "OnBoard-module--quesColor--2c7af";
export var quesColorSelected = "OnBoard-module--quesColorSelected--e9325";
export var steps = "OnBoard-module--steps--9afb9";
export var stepsData = "OnBoard-module--stepsData--32293";
export var textColor = "OnBoard-module--textColor--51d4a";