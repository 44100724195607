// extracted by mini-css-extract-plugin
export var crossImg = "Role-module--crossImg--655bc";
export var description = "Role-module--description--06671";
export var faqsNum = "Role-module--faqsNum--6ee4c";
export var faqsSub = "Role-module--faqsSub--e8725";
export var head = "Role-module--head--141a9";
export var headr = "Role-module--headr--6437c";
export var quesColor = "Role-module--quesColor--348a6";
export var quesColorSelected = "Role-module--quesColorSelected--8aa35";
export var reverse = "Role-module--reverse--f626d";
export var sectionRolehireCTO = "Role-module--sectionRolehireCTO--ea7fc";
export var textColor = "Role-module--textColor--010bd";