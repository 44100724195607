import React from "react"
import * as styles from "./Hello.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Hello = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.HelloCTO}>
        <Container className={styles.CToHelloCOntainer}>
          <Row>
            <Col>
              <h2
                className={`${styles.heading}`}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <div
                className={`${styles.description}`}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div className={styles.cards}>
                {strapiData?.cards &&
                  strapiData?.cards?.map((item, index) => (
                    <div className={styles.talent} key={index}>
                      <img
                        className={styles.tick}
                        src="https://invozone-backend.s3.us-east-1.amazonaws.com/tick_white_ccbe127b1d.svg"
                        alt={item?.title}
                        style={{ height: "27px", width: "27px" }}
                      />
                      <div className={styles.text}>
                        <p>{item?.title}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Hello
