// extracted by mini-css-extract-plugin
export var card = "Required-module--card--785d7";
export var cardWrapper = "Required-module--cardWrapper--216c0";
export var description = "Required-module--description--86d3a";
export var descriptionCtoBackground = "Required-module--descriptionCtoBackground--90ea3";
export var heading = "Required-module--heading--dce61";
export var loader = "Required-module--loader--95c8c";
export var lodaMoreBtn = "Required-module--lodaMoreBtn--d97d4";
export var lodalessBtn = "Required-module--lodalessBtn--837f2";
export var outsourceHead = "Required-module--outsourceHead--5dbd9";
export var requiredHireCTO = "Required-module--requiredHireCTO--dae02";
export var showlessdiv = "Required-module--showlessdiv--d72d8";
export var spin = "Required-module--spin--a3c11";