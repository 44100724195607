// extracted by mini-css-extract-plugin
export var btn = "Goals-module--btn--9012b";
export var cards = "Goals-module--cards--7df61";
export var description = "Goals-module--description--75037";
export var description2 = "Goals-module--description2--741ca";
export var goalsHireCTO = "Goals-module--goalsHireCTO--cfb87";
export var hireImg = "Goals-module--hireImg--2e14e";
export var hireTechnical = "Goals-module--hireTechnical--53f3c";
export var subTitle = "Goals-module--subTitle--f01b5";
export var talent = "Goals-module--talent--42f7a";
export var teamBtn = "Goals-module--teamBtn--3cf63";
export var text = "Goals-module--text--dcd03";
export var tick = "Goals-module--tick--bf6bf";