import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../components/common/ContactSales"
import MainLayout from "../layouts/MainLayout"
import Projects from "../components/web-application/Projects"
import Opportunity from "../components/web-application/Opportunity"
import ClientsWeb from "../components/web-application/ClientsWeb"
import ProjectsMobile from "../components/web-application/ProjectsMobile"
import Banner from "../components/hire-cto/Banner"
import WhyRemoteCTO from "../components/hire-cto/WhyRemoteCTO"
import Faqs from "../components/common/Faqs2"
import CmsProcess from "../components/cms/CmsProcess"
import Hello from "../components/hire-cto/Hello"
import OnBoard from "../components/hire-cto/OnBoard"
import Goals from "../components/hire-cto/Goals"
import Role from "../components/hire-cto/Role"
import Required from "../components/hire-cto/Required"
export const Head = () => {
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <title>Hire CTO To Boost Your Project Success </title>
      <meta
        name="description"
        content="Hire a CTO with InvoZone for expert guidance, strategic vision, and project success. Grow your team the right way. Contact us for a free consultation now!"
      />
      <meta name="robots" content="noindex, nofollow"></meta>
      <meta name="googlebot" content="noindex, nofollow"></meta>
    </>
  )
}
const hireCto = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const whyHireCTO = data?.strapiPage?.sections[1]
  const hello = data?.strapiPage?.sections[2]
  const onboard = data?.strapiPage?.sections[3]
  const goals = data?.strapiPage?.sections[4]
  const quality = data?.strapiPage?.sections[5]
  const role = data?.strapiPage?.sections[6]
  const required = data?.strapiPage?.sections[7]
  const search = data?.strapiPage?.sections[8]
  const process = data?.strapiPage?.sections[9]
  const experienced = data?.strapiPage?.sections[10]
  const projects = data?.strapiPage?.sections[11]
  const clients = data?.strapiPage?.sections[12]
  const faqs = data?.strapiPage?.sections[13]

  return (
    <MainLayout>
      <Banner strapiData={heroSection} />
      <WhyRemoteCTO strapiData={whyHireCTO} />
      <Hello strapiData={hello} />
      <OnBoard strapiData={onboard} />
      <Goals strapiData={goals} />
      <Opportunity strapiData={quality} hireCtoOpportunity={true} />
      <Role strapiData={role} />
      <Required strapiData={required} />
      <Opportunity strapiData={search} hireCtoOpportunity={true} />
      <CmsProcess strapiData={process} hireCtoProcess={true} />
      <Opportunity strapiData={experienced} cmsopportunity={true} />
      {!isMobile && !isTablet ? (
        <Projects strapiData={projects} hireCtoProjects={true} />
      ) : (
        <ProjectsMobile strapiData={projects} />
      )}
      <ClientsWeb strapiData={clients} nocodeclient={true} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "hire-cto" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
export default hireCto
