import React, { useEffect, useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import "../cyber-security/SimpleSteps.css"
import * as styles from "./Role.module.scss"
import "./Role.scss"
import "./RoleProgress.scss"

const ContextAwareToggle = ({ children, eventKey, callback, ind }) => {
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = ind === eventKey

  return (
    <Accordion.Toggle
      className={isCurrentEventKey ? styles.headr : ""}
      as={Card.Header}
      onClick={decoratedOnClick}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={
            isCurrentEventKey ? styles.quesColorSelected : styles.quesColor
          }
        >
          {children}
        </div>
      </div>
    </Accordion.Toggle>
  )
}

const Role = ({ strapiData }) => {
  const [imageInd, setImageInd] = useState(0)
  const [current, setCurrent] = useState(0)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(progress => {
        const nextProgress = progress + 1
        const sliderProgress = document.querySelector(`.isActivee`)

        if (sliderProgress) {
          sliderProgress.style.height = `${progress}%`

          if (current !== imageInd) {
            const elementWidth = window.getComputedStyle(sliderProgress).height

            if (parseFloat(elementWidth) > 0) {
              sliderProgress.style.height = `0%`
            }

            setImageInd(current)
            setProgress(0)
          }

          if (nextProgress > 100) {
            sliderProgress.style.height = `0%`

            const nextIndex = imageInd + 1 >= 3 ? 0 : imageInd + 1
            setImageInd(nextIndex)
            setCurrent(nextIndex)
            return 0
          }
          return nextProgress
        }
        return progress
      })
    }, 40)

    return () => clearInterval(interval)
  }, [progress, current, imageInd])

  return (
    <div className={`${styles.sectionRolehireCTO} `}>
      <Container>
        <Row className="align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <h2
              className={`${styles.head}  mb-0`}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
            <div className="faqsAccordian hireCTORoleSLider">
              <Accordion defaultActiveKey={`ek-${imageInd}`}>
                {strapiData?.cards?.map((el, i) => (
                  <Card
                    key={i}
                    border="light"
                    style={{ overflow: "visible" }}
                    onClick={() => setCurrent(i)}
                  >
                    <ContextAwareToggle
                      eventKey={`ek-${i}`}
                      ind={`ek-${imageInd}`}
                    >
                      <div className={styles.faqsNum}>
                        <h3
                          className={`${styles.faqsSub} py-2 mb-0`}
                          type="button"
                        >
                          {el?.title}
                        </h3>
                      </div>
                    </ContextAwareToggle>
                    <Accordion.Collapse
                      className={`${imageInd === i && "show"}`}
                    >
                      <Card.Body className={styles.textColor}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: el?.description?.description,
                          }}
                        />
                        <div
                          className={`progress-barr`}
                          id={`sliderProgress-${i}`}
                        >
                          <span
                            className={`${imageInd === i && "isActivee"}`}
                          ></span>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={styles.crossImg}>
              <img
                src={
                  strapiData?.cards[imageInd]?.image1 &&
                  strapiData?.cards[imageInd]?.image1[0]?.localFile?.publicURL
                }
                decoding="async"
                loading="lazy"
                alt={
                  strapiData?.cards[imageInd]?.image1 &&
                  strapiData?.cards[imageInd]?.image1[0]?.alternativeText
                }
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Role
