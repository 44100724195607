import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import * as styles from "./Goals.module.scss"

const Goals = ({ strapiData, outsourcingimage }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.goalsHireCTO}>
      <Container>
        <Row className="gap-30 align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={styles.hireTechnical}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div
                className={styles.description2}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle2,
                }}
              />
            </div>
            <div className={styles.cards}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={styles.talent} key={index}>
                    <img
                      className={styles.tick}
                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/tick_a35f121ca6.png"
                      style={{ height: "24px", width: "24px" }}
                      alt={item?.title}
                      decoding="async"
                      loading="lazy"
                    />
                    <div className={styles.text}>
                      <p>{item?.title}</p>
                    </div>
                  </div>
                ))}
            </div>
            <div className={`${styles.teamBtn}`}>
              {strapiData?.buttons[0] && (
                <Link to={strapiData?.buttons[0]?.url} className={styles.btn}>
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <div className={` text-center ${styles.hire}`}>
              {image ? (
                <GatsbyImage
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  image={image}
                  alt={strapiData?.title}
                  class={styles.hireImg}
                />
              ) : (
                <img
                  src={strapiData?.secImages[0]?.localFile?.publicURL}
                  alt={strapiData?.title}
                  decoding="async"
                  loading="lazy"
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Goals
